<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    :retain-focus="false"
    :value="dialog"
    :max-width="500"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <v-form
          @submit.prevent="save"
          ref="form"
          autocomplete="off"
          lazy-validation
          v-model="valid"
        >
          <div class="mb-6 text-center body-2">
            Escolha um parceiro e informe seu login e senha de acesso
          </div>

          <BasePartnersByUser
            id="partnerId"
            name="partnerId"
            label="Parceiro"
            required
            :rules="[required]"
            :userId="userId"
            v-model="model.partnerId"
          />

          <v-text-field
            id="login"
            name="login"
            label="Login"
            autocomplete="off"
            outlined
            dense
            required
            :rules="[required]"
            v-model="model.login"
          />

          <BasePassword
            id="password"
            name="password"
            label="Senha"
            autocomplete="off"
            outlined
            dense
            required
            :rules="[required]"
            v-model="model.password"
          />

          <v-divider class="mb-4" />

          <BaseButton
            block
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { PartnerService } from '@/services/api/partners';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false
    },
    userId: {
      type: String,
      required: true
    },
    partnerId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      partnerId: null,
      login: null,
      password: null
    }
  }),

  created() {
    if (this.isEdit) {
      this.search();
    }
  },

  methods: {
    async search() {
      try {
        const partnerService = new PartnerService();
        const { status, data } = await partnerService.getPartnerUser(
          this.partnerId,
          this.userId
        );

        if (status === 200) {
          this.model = data;
          this.$refs.form.resetValidation();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    save() {
      if (!this.$refs.form.validate(true)) return;

      const params = {
        userId: this.userId,
        ...this.model
      };

      if (this.isEdit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const partnerService = new PartnerService();
        const { status } = await partnerService.postPartnerUser(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const partnerService = new PartnerService();
        const { status } = await partnerService.putPartnerUser(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeModal(search) {
      this.$emit('closeModal', search);
    }
  }
};
</script>
